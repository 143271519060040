import { captureException } from '@sentry/browser';
import { useCallback, useEffect } from 'react';
import { useUserProfileSetting } from 'src/contexts/profile-settings-context';

const USER_TOGGLES_LOCAL_STORAGE_ID = 'USER_TOGGLES';
// Feature flag toggles
export const KNOWN_TOGGLES = [
  'ENABLE_DEV_MODE',
  'ENABLE_BEHAVIORS',
  'ENABLE_DEMO_DATA',
  'ENABLE_ORG_CHART',
  'ENABLE_RVA_REQUEST',
  'ENABLE_SINGLE_BOOTUP',
  'ENABLE_CLICK_TO_ROUTE',
  'ENABLE_CONSTRAINT_EDITOR',
  'ENABLE_VEHICLE_SCHEDULING',
  'ENABLE_ZOOM_ON_BIG_SCREENS',
  'ENABLE_COMMERCIAL_ANALYTICS',
  'ENABLE_SINGLE_VEHICLE_CONFIG',
  'ENABLE_FLEET_MESSAGING_SYSTEM',
  'ENABLE_TABLE_KEYBOARD_NAVIGATION',
  'ENABLE_FLEET_MONITORING_HIDE_ERRORS',
  'ENABLE_FLEET_MONITORING_HIDE_PARTNERS',
] as const;
export type ToggleName = (typeof KNOWN_TOGGLES)[number];
type NegatedToggleOption = `!${ToggleName}`;
export type ToggleOption = ToggleName | NegatedToggleOption;

const isValidToggleList = (toggles: unknown): toggles is ToggleName[] =>
  Array.isArray(toggles) &&
  toggles.every(
    (toggle) =>
      typeof toggle === 'string' &&
      KNOWN_TOGGLES.includes(toggle as ToggleName),
  );

const isNegatedToggleOption = (
  toggleName?: unknown,
): toggleName is NegatedToggleOption =>
  typeof toggleName === 'string' &&
  toggleName.startsWith('!') &&
  KNOWN_TOGGLES.includes(toggleName.slice(1) as ToggleName);

const toggleNameOfNegatedToggleOption = (toggle: NegatedToggleOption) =>
  toggle.slice(1) as ToggleName;

const CACHED_TOGGLES: ToggleOption[] = (() => {
  const cachedTogglesString = localStorage.getItem(
    USER_TOGGLES_LOCAL_STORAGE_ID,
  );
  if (!cachedTogglesString) {
    return [];
  }

  let cachedToggles: unknown;
  try {
    cachedToggles = JSON.parse(cachedTogglesString);
  } catch (_error) {
    const error = _error as Error;

    captureException(
      new Error(`Failed to parse toggles: ${cachedTogglesString}`, {
        cause: error,
      }),
    );
    localStorage.removeItem(USER_TOGGLES_LOCAL_STORAGE_ID);
    return [];
  }

  if (!isValidToggleList(cachedToggles)) {
    captureException(
      new TypeError(`Invalid user toggles: ${cachedTogglesString}`),
    );
    localStorage.removeItem(USER_TOGGLES_LOCAL_STORAGE_ID);
    return [];
  }

  return cachedToggles;
})();

export const useGetHasToggle = () => {
  const { setting: _toggles } = useUserProfileSetting('toggles');
  const toggles = _toggles || CACHED_TOGGLES;

  useEffect(
    function updateTOGGLES() {
      if (!toggles) return;

      localStorage.setItem(
        USER_TOGGLES_LOCAL_STORAGE_ID,
        JSON.stringify(toggles),
      );
    },
    [toggles],
  );

  return useCallback(
    (toggleName?: ToggleOption) => {
      if (!toggleName || !toggles) {
        return null;
      }

      if (isNegatedToggleOption(toggleName)) {
        return !toggles.includes(toggleNameOfNegatedToggleOption(toggleName));
      }

      return toggles.includes(toggleName);
    },
    [toggles],
  );
};

export const useHasToggle = (toggleName?: ToggleOption) => {
  return useGetHasToggle()(toggleName);
};
